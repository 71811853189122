/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, ColumnWrap, ColumnWrapper, Title, Text, ContactForm, Button } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout title={"THEHUB"}>
        <SiteHeader />

        <Column className="css-k8sy1l pb--60 pt--80" name={"intro-1"} css={css`
      
    background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=350x_.png);
    @media (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=660x_.png);
    }
    @media (min-width: 661px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=860x_.png);
    }
    @media (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=1400x_.png);
    }
    @media (min-width: 1401px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=2000x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (max-width: 350px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=660x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 351px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=1400x_.png);
    }
    @media (-webkit-min-device-pixel-ratio: 2) and (min-width: 861px) {
      background-image: url(https://cdn.swbpg.com/t/37641/c21214e74bef443a9ae887e19e857cec_s=3000x_.png);
    }
  
    `}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 pb--10 pt--10 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Title className="title-box title-box--shadow4 fs--62" style={{"maxWidth":""}} content={"<span style=\"caret-color: rgb(255, 255, 255); color: rgb(255, 255, 255);\">ASK THEHUB</span><br>"}>
              </Title>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"6y4e5j2tx1l"} style={{"paddingTop":47,"paddingBottom":0}}>
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper className="--shape3 --shadow2" style={{"maxWidth":800}}>
              
              <Title className="title-box" style={{"maxWidth":1072,"paddingRight":0}} content={"<span style=\"color: var(--color-blend--05);\">JOIN OR ASK THEHUB</span>"}>
              </Title>

              <Text className="text-box" content={"<span style=\"color: var(--black);\">Please make sure to fill in your name, email address, phone number, and any other relevant details. Once you've completed click on \"Send\"</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pb--50" name={"information"} style={{"paddingTop":0}}>
          
          <ColumnWrap className="column__flex js-anim  --anim4 --anim-s3 --center el--1 flex--center" anim={"4"} animS={"3"} columns={"1"}>
            
            <ColumnWrapper className="--shape3 --center pb--12 pt--10" style={{"maxWidth":""}}>
              
              <ContactForm className="--shape2 --shape3 --style3 --shadow4 ff--3 fs--14 w--300 ls--004 lh--16" style={{"maxWidth":740,"paddingTop":0,"marginBottom":45,"paddingBottom":38}} action={"/contact"} fields={[{"name":"Full name","type":"text","required":true,"placeholder":"Enter your full name"},{"name":"Contact e-mail address:","type":"email","required":true,"placeholder":"Enter your e-mail"},{"name":"Telephone number","type":"text","placeholder":"Telephone No."},{"name":"Message","type":"textarea","required":true,"placeholder":"Enter your message"},{"name":"Send","type":"submit","align":"center"}]}>
              </ContactForm>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column name={"footern"} style={{"marginTop":148,"paddingTop":28,"paddingBottom":34,"backgroundColor":"rgba(32, 32, 32, 1)"}} layout={"l1"}>
          
          <ColumnWrap className="column__flex --left el--3 flex--top" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
            
            <ColumnWrapper >
              
              <Title className="title-box title-box--left fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">THE HUB</span>"}>
              </Title>

              <Text className="text-box fs--12" style={{"maxWidth":806}} content={"<span style=\"color: var(--color-custom-1--95);\">Tillgängligheten 1<br>417 10 Gothenburg<br></span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper style={{"maxWidth":627,"paddingBottom":0}}>
              
              <Title className="title-box title-box--center fs--18" content={"<span style=\"color: rgb(246, 246, 248);\">ADRESS</span><br>"}>
              </Title>

              <Text className="text-box text-box--center fs--12" style={{"maxWidth":269}} content={"<span style=\"color: var(--color-custom-2--95);\">THEHUB&nbsp; AB<br>559470-2820</span><br>"}>
              </Text>

              <Button className="btn-box btn-box--hvr2 btn-box--pbtn3 btn-box--shape3 btn-box--center fs--12" use={"page"} href={"/contact#contact"} style={{"maxWidth":802,"marginTop":8,"paddingTop":9}} target={null} content={"<span style=\"font-weight: bold;\">Contact&nbsp;us</span>"}>
              </Button>

            </ColumnWrapper>

            <ColumnWrapper className="--right">
              
              <Title className="title-box fs--18" content={"<span style=\"color: var(--color-custom-1--95);\">MENU</span>"}>
              </Title>

              <Text className="text-box fs--12 w--500 ls--04 lh--14" content={"<a href=\"#\" style=\"\"><span style=\"color: var(--color-custom-1--95);\">Home</span><br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Brands<br>THE HUB<br></a><a href=\"#\" style=\"color: var(--color-custom-2--95);\">Contact</a><br>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

          <ColumnWrap className="column__flex --left el--3" style={{"backgroundColor":"rgba(32,32,32,1)"}} columns={"3"}>
          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}